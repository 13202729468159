.featureContainer{
    margin-top: 10px;
    margin-bottom: 100px;

    .coreFeature{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2{
            padding-top: 100px;
        }

        p{
            font-size: 16px;
            font-weight: 500;
            color: var(--primary-text-color);
            padding-top: 20px;
            margin-bottom: 0;
        }
    }
  
}