.footerContainer{
    background:#ECEBEB ;
    padding:50px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
        .locationContainer{
          
            display: flex;
            flex-direction: column;
            justify-content: center;
            h3{
                font-size: 16px;
                font-weight: 700;
                color: var(--primary-text-color);
                margin-bottom: 0;
                line-height: 28px;
            }
            p{
                font-size: 16px;
                font-weight: 500;
                color: var(--primary-text-color);
                margin-bottom: 0;
                line-height: 28px;
            }
        }
        .contactContainer{
            p{
                font-size: 16px;
                font-weight: 500;
                color: var(--primary-text-color);
                margin-bottom: 0;
                line-height: 28px;
            }
            
            
        }
        .socialLinkContainer{
            margin-top:15px ;
            display:flex;
            align-items: center;
            justify-content: flex-start;
            .socialLinks{
                padding: 10px;
                background: var(--secondary-color);
                border-radius: 5px;
            }
        }

    }
    .borderLine{
        height: 1px;
        background:#cfcece;
        margin-top: 40px;
    }
    .copyright{
        display: flex;
        align-items: center;
        justify-content:center ;
        p{
            font-size: 16px;
            font-weight: 500;
            color: var(--primary-text-color);
            margin-bottom: 0;
            margin-top: 15px;
        }

}

@media only screen and (max-width: 992px) { 
    .footerContainer{
        .logoContainer{
            padding-bottom: 5px;
        }

    }
}

// @media(max-width: 992px){
//     .footerContainer{
//         margin-top: 980px;
//     }
// }
// @media(max-width: 576px){
//     .footerContainer{
//         margin-top: 980px;
//     }
// }
