.footerContainer {
  background: #ECEBEB;
  padding: 50px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}
.footerContainer .locationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerContainer .locationContainer h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-text-color);
  margin-bottom: 0;
  line-height: 28px;
}
.footerContainer .locationContainer p {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 0;
  line-height: 28px;
}
.footerContainer .contactContainer p {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 0;
  line-height: 28px;
}
.footerContainer .socialLinkContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footerContainer .socialLinkContainer .socialLinks {
  padding: 10px;
  background: var(--secondary-color);
  border-radius: 5px;
}

.borderLine {
  height: 1px;
  background: #cfcece;
  margin-top: 40px;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyright p {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 0;
  margin-top: 15px;
}

@media only screen and (max-width: 992px) {
  .footerContainer .logoContainer {
    padding-bottom: 5px;
  }
}/*# sourceMappingURL=Footer.css.map */