.ModalHeaderSection {
  border-bottom: none;
  position: absolute;
  z-index: 11;
  right: 0;
}

.modal.show .modal-dialog {
  top: 25%;
}

.modal-body {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body h1 {
  color: var(--primary-text-color);
  font-weight: 700;
  margin: 0;
}

.Support {
  width: 100%;
  background: url("../../assets/contactBgImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0;
}
.Support .Title h5 {
  color: var(--primary-color);
  font-weight: bold;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.156);
}
.Support .Title h3 {
  color: var(--primary-text-color);
  font-weight: bold;
}
.Support .Contact_Doc {
  background: #fff;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px 60px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 100%;
}
.Support .Contact_Doc h4 {
  font-weight: bold;
  color: var(--primary-text-color);
  padding-left: 20px;
  margin: 0;
}

@media (max-width: 576px) {
  .Support {
    padding: 60px 0;
  }
}/*# sourceMappingURL=Support.css.map */