.ContactInfo {
  width: 100%;
  height: 135vh;
}
.ContactInfo .ContactBg {
  background: url("../../assets/contactBgImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  padding: 70px 0;
}
.ContactInfo .Padding-1 {
  background: var(--primary-color);
  box-shadow: 0px 0px 158px 6px rgba(0, 0, 0, 0.11);
}
.ContactInfo .Padding-2 {
  background: #fff;
  box-shadow: 0px 0px 158px 6px rgba(0, 0, 0, 0.11);
}
.ContactInfo .ContactDetails {
  height: 100%;
  background: var(--primary-color);
  display: flex;
  justify-content: start;
  padding: 40px 80px;
  align-items: center;
}
.ContactInfo .ContactDetails h5 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  color: var(--primary-text-color);
  padding-bottom: 30px;
  font-size: 24px;
}
.ContactInfo .ContactDetails h1 {
  padding-bottom: 30px;
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-text-color);
}
.ContactInfo .ContactDetails h6 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-text-color);
  padding-left: 10px;
}
.ContactInfo .ContactDetails .SocialBtn {
  margin-top: 150px;
}
.ContactInfo .ContactDetails .SocialBtn button {
  border: none;
  background: none;
}
.ContactInfo .ContactForm {
  background: #fff;
  padding: 70px 70px 60px 70px;
  height: 100%;
}
.ContactInfo .ContactForm .InputType {
  position: relative;
  margin-bottom: 80px;
}
.ContactInfo .ContactForm .InputType .TextArea {
  position: absolute;
  top: 7px;
}
.ContactInfo .ContactForm .InputType .form-control {
  border: 2px solid var(--primary-color);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 20px;
  padding-left: 80px;
}
.ContactInfo .ContactForm .InputType input {
  height: 55px;
}
.ContactInfo .ContactForm .InputType .form-control:focus {
  box-shadow: none;
}
.ContactInfo .ContactForm .InputType img {
  position: absolute;
  margin-left: 30px;
}
.ContactInfo .ContactForm button {
  background: var(--primary-color);
  border: none;
  padding: 13px 60px 8px 60px;
  border-radius: 50px;
  margin: 0;
  font-weight: 700;
  font-size: 17px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.167);
}
.ContactInfo .ContactForm button img {
  margin-bottom: 8px;
  padding-left: 7px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media (max-width: 576px) {
  .ContactInfo .ContactForm {
    padding: 70px 20px 60px 20px;
  }
  .ContactInfo .ContactDetails {
    padding: 40px 20px;
  }
}
@media (max-width: 768px) {
  .ContactInfo .ContactDetails h5 {
    font-size: 16px;
  }
  .ContactInfo .ContactDetails h1 {
    font-size: 34px;
  }
}
@media (max-width: 992px) {
  .ContactInfo {
    height: 170vh;
  }
}/*# sourceMappingURL=ContactInfo.css.map */