
@media (max-width: 992px){
    .SideNav_Content{
        display: flex;
        justify-content: end;
        margin-top: 100px;
        margin-right: 40px;
        .Contact_us{
            display: none;
        }
        .NavbarItem{
            .NavbarLink{
                text-decoration: none;
                h6{
                    text-align: end;
                    margin: 5px 0 0 0;
                    width: 250px;
                    padding: 13px 30px;
                    border-radius: 5px;
                    color: var(--primary-text-color);
                    font-weight: bold;
                    font-size: 16px;
                }
                .active{
                    background: #fff;
                    border-right: 10px solid var(--primary-text-color);
                }
            }
    
        }
    }
}


@media (max-width: 768px){
    .SideNav_Content{
        position: relative;
        .Contact_us{
            width: 100%;
            height: 40vh;
            display: flex;
            align-items: end;
            justify-content: end;
            .Contact_Btn{
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    color: #fff;
                    font-weight: 700;
                    
                }
                img{
                    padding: 9px;
                    margin-right: 10px;
                    border: 2px solid #fff;
                    border-radius: 5px;
                }
            }
        }
        
    }
}

