@media (max-width: 992px) {
  .SideNav_Content {
    display: flex;
    justify-content: end;
    margin-top: 100px;
    margin-right: 40px;
  }
  .SideNav_Content .Contact_us {
    display: none;
  }
  .SideNav_Content .NavbarItem .NavbarLink {
    text-decoration: none;
  }
  .SideNav_Content .NavbarItem .NavbarLink h6 {
    text-align: end;
    margin: 5px 0 0 0;
    width: 250px;
    padding: 13px 30px;
    border-radius: 5px;
    color: var(--primary-text-color);
    font-weight: bold;
    font-size: 16px;
  }
  .SideNav_Content .NavbarItem .NavbarLink .active {
    background: #fff;
    border-right: 10px solid var(--primary-text-color);
  }
}
@media (max-width: 768px) {
  .SideNav_Content {
    position: relative;
  }
  .SideNav_Content .Contact_us {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: end;
    justify-content: end;
  }
  .SideNav_Content .Contact_us .Contact_Btn {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SideNav_Content .Contact_us .Contact_Btn span {
    color: #fff;
    font-weight: 700;
  }
  .SideNav_Content .Contact_us .Contact_Btn img {
    padding: 9px;
    margin-right: 10px;
    border: 2px solid #fff;
    border-radius: 5px;
  }
}/*# sourceMappingURL=SideNav.css.map */