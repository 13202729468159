.topNavContainer {
  width: 100%;
  height: 75px;
  background: #fff;
  padding-top: 8px;
  position: sticky;
  top: 0;
  z-index: 111;
}
.topNavContainer nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topNavContainer nav .NavbarItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  margin: 0 30px;
}
.topNavContainer nav .NavbarItem .NavbarLink {
  text-decoration: none;
  padding: 0 20px;
  margin: 0 5px;
  position: relative;
}
.topNavContainer nav .NavbarItem .NavbarLink h6 {
  font-size: 14px;
  margin: 0;
  color: var(--primary-text-color);
  font-weight: bold;
}
.topNavContainer nav .NavbarItem .NavbarLink img {
  position: absolute;
  margin-top: 5px;
}
.topNavContainer nav .Search {
  position: relative;
}
.topNavContainer nav .Search .form-control {
  width: 390px;
  height: 39px;
  background: var(--input-bg-color);
  border: none;
  padding: 0 25px;
  border-radius: 5px;
}
.topNavContainer nav .Search .form-control:focus {
  box-shadow: none;
}
.topNavContainer nav .Search button {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 7px;
  padding: 0 15px;
  background: var(--input-bg-color);
}
.topNavContainer nav .Sign_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text-color);
  font-weight: bold;
  background: none;
  border: none;
  margin-right: 30px;
}
.topNavContainer nav .Sign_Btn img {
  padding: 0 6px;
}
.topNavContainer nav .Contact_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text-color);
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: none;
}
.topNavContainer nav .Contact_Btn span {
  color: var(--primary-color);
  padding-left: 7px;
}
.topNavContainer nav .Contact_Btn img {
  border: 3px solid var(--primary-color);
  padding: 5px 6px;
  border-radius: 5px;
}
.topNavContainer nav .OpenBtn {
  background: none;
  border: 3px solid var(--primary-color);
  padding: 10px 12px;
  border-radius: 50%;
  margin-left: 40px;
  display: none;
}

.topNavContainer .SideNavOpen {
  display: none;
}
.topNavContainer .SideNavClose {
  display: none;
}

@media (max-width: 992px) {
  .topNavContainer nav .NavbarItem {
    display: none;
  }
  .topNavContainer nav .OpenBtn {
    display: block;
  }
  .topNavContainer .SideNavOpen {
    width: 350px;
    height: 100vh;
    background: var(--primary-color);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1111111;
    transition: all 0.7s ease-in-out;
    display: block;
  }
  .topNavContainer .SideNavClose {
    width: 350px;
    height: 100vh;
    background: var(--primary-color);
    position: fixed;
    right: -400px;
    top: 0;
    z-index: 1111111;
    transition: all 0.7s ease-in-out;
    display: block;
  }
  .topNavContainer .CloseBtn {
    background: none;
    border: none;
  }
}
@media (max-width: 768px) {
  .topNavContainer nav .Contact_Btn {
    display: none;
  }
}/*# sourceMappingURL=TopNav.css.map */