.featureContainer {
  margin-top: 10px;
  margin-bottom: 100px;
}
.featureContainer .coreFeature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.featureContainer .coreFeature h2 {
  padding-top: 100px;
}
.featureContainer .coreFeature p {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-text-color);
  padding-top: 20px;
  margin-bottom: 0;
}/*# sourceMappingURL=FeatureSection.css.map */