.cardContainer{
    height: 100%;
    display: flex;
    align-items: center;
    .imgContainer{
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    p{
        font-size: 16px;
        margin-bottom: 0;
        color: #fff;
        line-height: 32px;
        font-weight: 600;
        padding-left: 30px;
        text-shadow: var(--text-shadow);
    }

}





@media only screen and (max-width: 1200px) { 
    
}

@media only screen and (max-width: 992px) { 
   

}
@media only screen and (max-width: 768px) { 
   
}
@media only screen and (max-width: 576px) { 
  
}
@media only screen and (max-width: 320px) { 
  
}