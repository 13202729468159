.cardContainer {
  height: 100%;
  display: flex;
  align-items: center;
}
.cardContainer .imgContainer {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardContainer p {
  font-size: 16px;
  margin-bottom: 0;
  color: #fff;
  line-height: 32px;
  font-weight: 600;
  padding-left: 30px;
  text-shadow: var(--text-shadow);
}/*# sourceMappingURL=FunctionalCard.css.map */