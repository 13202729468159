.coverSection{
    background: var(--secondary-color);
    padding: 80px 0px 80px 0px;
    .descriptionWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 15px;
        p{
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;
            color: var(--primary-text-color);
            margin-bottom: 0px;
            padding-bottom: 15px;
        }
        h1{
            font-weight: 700;
            // font-size: 28px;
            line-height: 38px;
            color: var(--primary-text-color);
            line-height: 60px;
        }
        a{
            border-style: none;
            background: var(--primary-color);
            width: 200px;
            padding: 12px 20px;
            color: #fff;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 600;
            border-radius: 5px;
            text-decoration: none;
            text-shadow: var(--text-shadow);
            text-align: center;
        }
    }
   
}

@media only screen and (max-width: 1200px) { 
    .coverSection{
        .descriptionWrapper{
            p{
                font-size: 20px;
            }
            h1{
                font-size: 24px;
                line-height: 38px;
            }
        }
       
    }
    
}

@media only screen and (max-width: 992px) { 
    .coverSection{
        .descriptionWrapper{
            p{
                font-size: 16px;
                padding-bottom: 10px;
            }
            h1{
                font-size: 20px;  
                line-height: 28px;
            }
        }
       
    }

}
@media only screen and (max-width: 768px) { 
    .coverSection{
        .descriptionWrapper{
            p{
                font-size: 16px;
            }
            h1{
                font-size: 32px;
                line-height: 45px;
            }
        }
       
    }

}
@media only screen and (max-width: 576px) { 
  
}
@media only screen and (max-width: 320px) { 
  
}
