.featureCardContainer {
  margin-top: 30px;
}
.featureCardContainer .featureCard {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--body-bg-color);
}
.featureCardContainer .featureCard .imgContainer {
  background: #f1f1f1;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featureCardContainer .featureCard h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-text-color);
  line-height: 25px;
  margin-top: 20px;
}
.featureCardContainer .featureCard p {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-color);
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 0;
}

.featureCard:hover {
  background: var(--secondary-color);
  border: 1px solid #f1f1f1;
}/*# sourceMappingURL=FeatureCard.css.map */