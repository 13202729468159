.functionalitySection{
    background: var(--primary-color);
    padding: 30px 0px;
    .FunctionalBorder{
        border-right: 2px solid #fff;
    }

}



@media (max-width: 992px){
    .functionalitySection{
        .FunctionalBorder{
            border-right: none;
            border-bottom: 2px solid #fff;
        }
    }
}


