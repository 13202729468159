
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

:root{
    --primary-color:#FFBF43;
    --secondary-color:#FFE9BF;
    --body-bg-color:#F7F7F7;
    --primary-text-color:#354856;
    --text-light:#A0A7B4;
    --input-bg-color:#DCDCDC;
    --content-bg-color:#ffffff;
    --text-shadow:0px 4px 4px rgba(0, 0, 0, 0.15);
}

body{
    background: var(--body-bg-color); 
    font-family: 'Nunito', sans-serif;
}


@mixin main($marginRight,$marginLeft) {
    margin: 0px $marginRight 0px $marginLeft;
}

::-webkit-scrollbar {
    width: 7px;
    margin: 0px 2px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: lightgray;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #655757; 
  }


  .layout{
    @include main(60px ,60px);
    position: relative;
 }

  h2{
    font-weight: 700;
    padding: 17px 0px;
    position: relative; 
    color: var(--primary-text-color);
 }
  h2::before{
    position: absolute;
    content: '';
    width: 90px;
    height: 4px;
    background: var(--primary-color);
    bottom: 0;
    border-radius: 25px;
    left: 30%;
}


  
  @media only screen and (max-width: 1200px) { 
  }

  @media only screen and (max-width: 992px) { 
    .layout{
      @include main(60px ,60px);
      position: relative;
   }

  }
  @media only screen and (max-width: 768px) { 
    .layout{
      @include main(10px ,10px);
      position: relative;
   }
  }
  @media only screen and (max-width: 576px) { 
    .layout{
      @include main(10px ,10px);
      position: relative;
   }
  }
  @media only screen and (max-width: 320px) { 
    
  }