.topNavContainer{
    width: 100%;
    height: 75px;
    background: #fff;
    // border: 1px solid;
    padding-top: 8px;
    position: sticky;
    top: 0;
    z-index: 111;
    nav{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid;
        .NavbarItem{
            display: flex;
            justify-content: space-around;
            align-items: center;
            // border: 1px solid;
            padding: 0 20px;
            margin: 0 30px;
            .NavbarLink{
                text-decoration: none;
                padding: 0 20px;
                // background: #000;
                margin: 0 5px;
                position: relative;
                h6{
                    font-size: 14px;
                    margin: 0;
                    color: var(--primary-text-color);
                    font-weight: bold;
                }
                img{
                    position: absolute;
                    margin-top: 5px;
                }
            }
        } 
        
        .Search{
            position: relative;
            .form-control{
                width: 390px;
                height: 39px;
                background: var(--input-bg-color);
                border: none;
                padding: 0 25px;
                border-radius: 5px;
            }
            .form-control:focus{
                box-shadow: none;
            }
            button{
                background: none;
                border: none;
                position: absolute;
                right: 0;
                top: 7px;
                padding: 0 15px;
                background: var(--input-bg-color);
            }
        }


        .Sign_Btn{
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary-text-color);
            font-weight: bold;
            background: none;
            border: none;
            margin-right: 30px;
            img{
                padding: 0 6px;
            }
        }

        .Contact_Btn{
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary-text-color);
            font-weight: bold;
            background: none;
            border: none;
            text-decoration: none;
            span{
                color: var(--primary-color);
                padding-left: 7px;
            }
            img{
                border: 3px solid var(--primary-color);
                padding: 5px 6px;
                border-radius: 5px;
            }
        }

        .OpenBtn{
            background: none;
            border: 3px solid var(--primary-color);
            padding: 10px 12px;
            border-radius: 50%;
            margin-left: 40px;
            display: none;
        }
    }
    
    
}

.topNavContainer{
    .SideNavOpen{
        display: none;
    }
    .SideNavClose{
        display: none;
    }
}


@media (max-width: 992px) {
    .topNavContainer{
        nav{
            .NavbarItem{
                display: none;
            }
            .OpenBtn{
                display: block;
            }
        }

        .SideNavOpen{
            width: 350px;
            height: 100vh;
            background: var(--primary-color);
            position: fixed;
            right: 0;
            top: 0;
            z-index: 1111111;
            transition: all 0.7s ease-in-out;
            display: block;
        }
        .SideNavClose{
            width: 350px;
            height: 100vh;
            background: var(--primary-color);
            position: fixed;
            right: -400px;
            top: 0;
            z-index: 1111111;
            transition: all 0.7s ease-in-out;
            display: block;
        }

        .CloseBtn{
            background: none;
            border: none;
        }
    }
}
@media (max-width: 768px) {
    .topNavContainer{
        nav{
            .Contact_Btn{
                display: none;
            }
        }
    }
}



